import React, { useState } from 'react'
import styled from 'styled-components'
import { COLOR_CONSTANTS, radius, space, fontSizes, colors } from 'theme'
import { pxToRem } from 'helpers'
import { Box, Flex, Grid } from 'components/Layout'
import { Text } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import Tooltip from 'components/Tooltip'
import Button from 'components/Button'

import imageCheckmark from 'static/images/listening/checkmark_circle_checked.svg'

const StyledIntervalWrapper = styled(Flex)`
  border: 1px solid #b6cef0;
`

const StyledInterval = styled(Flex)`
  cursor: pointer;
`

const PricingWrapper = styled(Flex)`
  background-color: ${COLOR_CONSTANTS.WHITE};
  height: 100%;
  border: 1px solid #b6cef0;
  border-radius: 24px;
`

const StyledBenefitsBox = styled(Box)`
  cursor: pointer;
`

const StyledButton = styled(Button.Primary)`
  padding-left: ${space.s};
  padding-right: ${space.s};
  min-width: ${space.xl};
  border-radius: ${radius.l};
  max-width: 100%;
  height: ${pxToRem(52)};
  min-height: ${pxToRem(52)};
  box-shadow: 0 18px 24px -8px rgba(36, 130, 253, 0.32);
  p {
    font-size: ${fontSizes.m};
  }

  &:hover {
    background-color: ${COLOR_CONSTANTS.WHITE};
    border: 1px solid ${colors.primary};
    color: ${colors.primary};
  }
`

const PRICE_INTERVALS = [
  { name: 'Monthly', key: 'month' },
  { name: 'Annually (Save Up to 33%)', key: 'year' },
]

const ACTIVE_PRICINGS = [
  {
    name: 'Tier 1',
    price: {
      base_month: 75,
      base_year: 720,
    },
    benefits: [
      {
        name: '10+ sources',
        description: '',
      },
      {
        name: '15K results per month',
        description: '',
      },
      {
        name: '50 results per source per day',
        description: '',
      },
    ],
  },
  {
    name: 'Tier 2',
    price: {
      base_month: 150,
      base_year: 1440,
    },
    benefits: [
      {
        name: '10+ sources',
        description: '',
      },
      {
        name: '45K results per month',
        description: '',
      },
      {
        name: '150 results per source per day',
        description: '',
      },
    ],
  },
  {
    name: 'Tier 3',
    price: {
      base_month: 250,
      base_year: 2400,
    },
    benefits: [
      {
        name: '10+ sources',
        description: '',
      },
      {
        name: '120K results per month',
        description: '',
      },
      {
        name: '400 results per source per day',
        description: '',
      },
    ],
  },
]

const PricingComponent = () => {
  const [priceInterval, setPriceInterval] = useState('month')

  return (
    <Flex flexDirection="column" width="100%">
      <Flex flexDirection="column" alignItems="center">
        <Flex flexDirection="column">
          <StyledIntervalWrapper borderRadius="20px">
            {PRICE_INTERVALS.map(({ name, key }) => (
              <StyledInterval
                key={key}
                onClick={() => {
                  if (priceInterval === 'month') {
                    setPriceInterval('year')
                  } else {
                    setPriceInterval('month')
                  }
                }}
                p="s"
                bg={priceInterval === key ? 'primary' : 'background_card'}
                borderRadius="20px"
              >
                <Text mx="m" color={priceInterval === key ? 'white' : 'secondaryText'}>
                  {name}
                </Text>
              </StyledInterval>
            ))}
          </StyledIntervalWrapper>
        </Flex>

        <Flex mb="m" flexDirection="column">
          <Text mt="s" pt="xs" color="primary" textAlign="center">
            Save 20% with yearly billing 🎉
          </Text>
        </Flex>
      </Flex>
      <Grid
        mt="l"
        gridTemplateColumns={{ mobile: '1fr', desktop: 'repeat(3, 1fr)' }}
        gridGap="m"
        mx="auto"
        width="100%"
        justifyItems="center"
        maxWidth="1110px"
      >
        {ACTIVE_PRICINGS.map((plan) => {
          const { name, price, benefits = [] } = plan

          const calculatedPrice = Number(price[`base_${priceInterval}`] || -1)

          return (
            <Flex flexDirection="column" width={{ mobile: '100%', desktopWide: '330px' }}>
              <PricingWrapper flexDirection="column" key={name} position="relative" p="l">
                <Flex flexDirection="column">
                  <Text fontWeight="bold" fontSize="xxxl" color={COLOR_CONSTANTS.DENIM} textAlign="left">
                    {name}
                  </Text>
                  <Flex
                    flexDirection="column"
                    minHeight={{ mobile: 'auto', desktop: '50px', desktopWide: '50px' }}
                    justifyContent="center"
                    mt="l"
                  >
                    <Flex alignItems="center">
                      <Text fontSize="26px" color="primary" fontWeight="medium" as="span">
                        {calculatedPrice === 0 ? '$0' : `$${calculatedPrice.toLocaleString()}`}
                      </Text>
                      <Flex ml="xxs" flexDirection="column">
                        <Text color="secondaryText" fontSize="m" fontWeight="bold">
                          /{priceInterval} per listener
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>

                  <Flex flexDirection="column" mt="m">
                    <Text mb="s" fontSize="m" textAlign="left" color="secondaryText" as="span" fontWeight="medium">
                      Each listener offers:
                    </Text>
                    {benefits.map(({ name, description }) =>
                      description ? (
                        <StyledBenefitsBox my="s" key={name}>
                          <Tooltip
                            width="250px"
                            wrapperComp={
                              <Flex key={name} alignItems="center">
                                <LocalImage src={imageCheckmark} width="20px" height="20px" alt="" />
                                <Text ml="s" fontSize="s" color="denimText">
                                  {name}
                                </Text>
                              </Flex>
                            }
                            message={description}
                            left="0"
                            isCursorPointer
                            isTriangleVisible={false}
                          />
                        </StyledBenefitsBox>
                      ) : (
                        <Flex key={name} alignItems="center" my="s" height="20px">
                          {name && <LocalImage src={imageCheckmark} width="20px" height="20px" alt="" />}

                          <Text ml="s" fontSize="s" color="denimText">
                            {name}
                          </Text>
                        </Flex>
                      )
                    )}
                  </Flex>
                </Flex>
              </PricingWrapper>
            </Flex>
          )
        })}
      </Grid>
    </Flex>
  )
}

export default PricingComponent
