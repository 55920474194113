import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Flex, Box } from 'components/Layout'
import LocalImage from 'components/LocalImage'

import imageHeader from 'static/images/listening/header/header_picture.webp'
import imageHeaderButton from 'static/images/listening/header/button.webp'
import imageHeaderMessage1 from 'static/images/listening/header/message_1.webp'
import imageHeaderMessage2 from 'static/images/listening/header/message_2.webp'

const StyledImageHeader = styled(LocalImage)`
  position: absolute;
`

const StyledImageHeaderMessage2 = styled(StyledImageHeader)`
  filter: drop-shadow(-10px -5px 28px rgba(26, 51, 100, 0.06)) drop-shadow(18px 13px 28px rgba(26, 51, 100, 0.1));
  backdrop-filter: blur(22px);

  ${({ coefficient }) => `  
  bottom: ${coefficient * -8}px;
  left: ${coefficient * 46}px;
  width: ${coefficient * 274}px;
  height: ${coefficient * 56}px`};

  animation: listening-image-header-media 8s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite both;
  @keyframes listening-image-header-media {
    0% {
      transform: scale(1) translateX(0) translateY(0);
    }
    16.6666666667% {
      transform: scale(1.12) translateX(0) translateY(24px);
    }
    33.3333333333% {
      transform: scale(1.12) translateX(0) translateY(24px);
    }
    50.0000000001% {
      transform: scale(1.12) translateX(-16px) translateY(48px);
    }
    66.6666666668% {
      transform: scale(1.12) translateX(-16px) translateY(48px);
    }
    83.3333333335% {
      transform: scale(1) translateX(0) translateY(0);
    }
    100% {
      transform: scale(1) translateX(0) translateY(0);
    }
  }
`

const StyledImageHeaderButton = styled(StyledImageHeader)`
  box-shadow: 7px 10px 20px rgba(1, 90, 255, 0.25);
  border-radius: 6px;
  cursor: pointer;

  ${({ coefficient }) => `  
  right: ${coefficient * 3}px;
  top: ${coefficient * 30}px;
  width: ${coefficient * 130.78}px;
  height: ${coefficient * 30.88}px`};

  animation: listening-image-header-button 4s linear infinite;
  @keyframes listening-image-header-button {
    0%,
    3% {
      transform: rotateZ(0);
    }
    8% {
      transform: rotateZ(-15deg);
    }
    10% {
      transform: rotateZ(10deg);
    }
    12% {
      transform: rotateZ(-10deg);
    }
    15% {
      transform: rotateZ(6deg);
    }
    18% {
      transform: rotateZ(-4deg);
    }
    20%,
    100% {
      transform: rotateZ(0);
    }
  }
`

const StyledImageHeaderMessage1 = styled(StyledImageHeader)`
  filter: drop-shadow(-10px -5px 34px rgba(26, 51, 100, 0.06)) drop-shadow(18px 13px 28px rgba(26, 51, 100, 0.1));
  backdrop-filter: blur(22px);

  ${({ coefficient }) => `  
  left: ${coefficient * 24}px;
  bottom: ${coefficient * 54}px;
  width: ${coefficient * 296}px;
  height: ${coefficient * 56}px`};

  animation: listening-image-header-questions 8s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite both;
  @keyframes listening-image-header-questions {
    0% {
      transform: scale(1) translateX(0) translateY(0);
    }
    16.6666666667% {
      transform: scale(1.12) translateX(0) translateY(-24px);
    }
    33.3333333333% {
      transform: scale(1.12) translateX(0) translateY(-24px);
    }
    50.0000000001% {
      transform: scale(1.12) translateX(16px) translateY(-48px);
    }
    66.6666666668% {
      transform: scale(1.12) translateX(16px) translateY(-48px);
    }
    83.3333333335% {
      transform: scale(1) translateX(0) translateY(0);
    }
    100% {
      transform: scale(1) translateX(0) translateY(0);
    }
  }
`

const HeaderImageAnimation = () => {
  // this is a coefficient of the image. Real size 500px, but on the site it is 400px, so the coefficient would be 400/500
  const [coefficient, setCoefficient] = useState(1)
  const imageWrapperRef = useRef(null)

  const countCoefficient = () => {
    if (imageWrapperRef && imageWrapperRef.current) {
      const { current: { clientWidth, firstChild: { naturalWidth } = {} } = {} } = imageWrapperRef
      const newCoefficient = (clientWidth * 2) / naturalWidth
      if (!Number.isNaN(newCoefficient) && newCoefficient > 0) {
        setCoefficient(newCoefficient)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', countCoefficient)

    return () => {
      window.removeEventListener('resize', countCoefficient)
    }
  }, [])

  return (
    <Flex width="100%">
      <Flex maxWidth="495px" mx="auto" pb={{ mobile: 'l', desktop: 0 }}>
        <Box width="100%" position="relative" ref={imageWrapperRef}>
          <LocalImage
            src={imageHeader}
            width="100%"
            mx="auto"
            alt="Uncover business-critical insights with social listening"
            onLoad={() => {
              countCoefficient()
            }}
          />

          <StyledImageHeaderMessage2 src={imageHeaderMessage2} coefficient={coefficient} />
          <StyledImageHeaderButton
            src={imageHeaderButton}
            coefficient={coefficient}
            onClick={() => {
              window.open('/pricing', '_self')
            }}
          />
          <StyledImageHeaderMessage1 src={imageHeaderMessage1} coefficient={coefficient} />
        </Box>
      </Flex>
    </Flex>
  )
}

export default HeaderImageAnimation
