import React from 'react'
import styled from 'styled-components'
import App from 'App'
import { COLOR_CONSTANTS, colors, radius, lineHeights, space, fontSizes } from 'theme'
import { CONTAINER_MAX_WIDTH, FREQUENTLY_ASKED_QUESTIONS, LISTENING } from 'consts'
import { pxToRem } from 'helpers'
import SEO from 'components/SEO'
import { Box, Flex, Grid } from 'components/Layout'
import Container from 'components/Container'
import { Text, H1, H2, H3 } from 'components/Typography'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import GetStartedComponent from 'components/GetStartedComponent'
import G2FeaturesComponent from 'components/G2FeaturesComponent'
import Awards from 'components/Awards'
import PoweredBy from 'components/PoweredByAI'
import TrialTerms from 'components/TrialTerms'
import Button from 'components/Button'
import Calendly from 'components/Calendly'

import imageFeatureCustomerExperience from 'static/images/listening/features/customer_experience.svg'

import imageFeatureAnalyze from 'static/images/listening/features/analyze.svg'
import imageFeatureRecognize from 'static/images/listening/features/recognize.svg'
import imageDoubleArrowRightBlue from 'static/images/home/double_arrow_right_blue.svg'

import networkImageBluesky from 'static/images/networks/bluesky.svg'
import networkImageFacebook from 'static/images/networks/facebook.svg'
import networkImageInstagtam from 'static/images/networks/instagram.svg'
import networkImageLinkedIn from 'static/images/networks/linkedin.svg'
import networkImageTwitter from 'static/images/networks/twitter.svg'
import networkImageGoogle from 'static/images/networks/googlebusiness.svg'
import networkImageTikTok from 'static/images/networks/tiktok.svg'
import networkImageReddit from 'static/images/networks/reddit.svg'
import networkImageYouTube from 'static/images/networks/youtube.svg'
import networkImagePinterest from 'static/images/networks/pinterest.svg'
import networkImageSnapchat from 'static/images/networks/snapchat2.svg'
import networkImageTumblr from 'static/images/networks/tumblr2.svg'

import imageFeatureListeningQueries from 'static/images/listening/image_listening_queries.webp'
import imageFeatureFullScope from 'static/images/listening/image_full_scope.webp'
import imageFeatureNoise from 'static/images/listening/image_noise.webp'

import imageFooterFeatureCrisisManagement from 'static/images/listening/footer_features/crisis_management.svg'
import imageFooterFeatureConsumerResearch from 'static/images/listening/footer_features/consumer_research.svg'
import imageFooterFeatureBrandHealth from 'static/images/listening/footer_features/brand_health.svg'
import imageFooterFeatureCompetitorComparison from 'static/images/listening/footer_features/competitor_comparison.svg'
import imageFooterFeatureTrendIdentification from 'static/images/listening/footer_features/trend_identification.svg'
import imageFooterFeatureSentimentResearch from 'static/images/listening/footer_features/sentiment_research.svg'
import imageFooterFeatureCampaignAnalysis from 'static/images/listening/footer_features/campaign_analysis.svg'
import imageFooterFeatureIndustryAnalysis from 'static/images/listening/footer_features/industry_analysis.svg'
import imageFooterFeatureInfluencerRecognition from 'static/images/listening/footer_features/influencer_recognition.svg'
import imageFooterFeatureConsumerFeedback from 'static/images/listening/footer_features/consumer_feedback.svg'
import SelectPlanButton from '../Home/components/SelectPlanButton'
import GridDropdown from '../Faq/GridDropdown'
import PricingComponent from './PricingComponent'
import HeaderImageAnimation from './HeaderImageAnimation'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const StyledLinkText = styled(Text)`
  display: flex;
  align-items: center;
`

const StyledNetworksWrapper = styled(Flex)`
  gap: ${space.sm};
  flex-wrap: wrap;
  align-items: center;
`

const StyledButtonScheduleDemo = styled(Button.Gradient)`
  box-shadow: 0 18px 24px -8px rgba(36, 130, 253, 0.32);
  width: 215px;
  height: 100%;
  font-size: ${fontSizes.m};
`

const StyledFooterFeaturesWrapper = styled(Flex)`
  flex-wrap: wrap;
  gap: ${space.m};
  justify-content: center;
`

const FEATURE_ITEMS = [
  {
    title: 'Create better customer experiences',
    description:
      'Proactively look for terms and keywords your customer use to discover their needs. Take the quick action to keep them happy.',
    image: imageFeatureCustomerExperience,
  },
  {
    title: 'Evaluate the efficiency of your social campaigns',
    description:
      'Learn how your target audience responds to your marketing campaigns to refine your approach and convert potential customers.',
    image: imageFeatureAnalyze,
  },
  {
    title: 'Recognize influencers in your space',
    description: 'Discover and identify ideal influencers for your brand’s marketing campaign or agency roster.',
    image: imageFeatureRecognize,
  },
]

const LISTENING_FEATURE_ITEMS = [
  {
    name: 'What is a listener?',
    description:
      'Each listener is defined by a set of keywords and can track data across multiple sources such as social networks, news, reviews sites and web search.',
  },
  {
    name: 'What is a source?',
    description:
      'Source is an actual social network, web or news search or a review site. Each listener can track 10+ sources. You can track latest news, web search as well as the following networks are currently supported:',
  },
]

// copy from src/routes/AIAssistant/AIAssistant.js
const NETWORKS = [
  { image: networkImageBluesky },
  { image: networkImageFacebook },
  { image: networkImageInstagtam },
  { image: networkImageLinkedIn },
  { image: networkImageTwitter },
  { image: networkImageGoogle },
  { image: networkImageTikTok },
  { image: networkImageReddit },
  { image: networkImageYouTube },
  { image: networkImagePinterest },
  { image: networkImageSnapchat },
  { image: networkImageTumblr },
]

const FOOTER_FEATURE_ITEMS = [
  {
    title: 'Crisis Management',
    description:
      'Stay ahead of a crisis with personalized notifications and detailed sentiment analysis to remain prepared and closely monitor ongoing conversations.',
    image: imageFooterFeatureCrisisManagement,
    link: '',
  },
  {
    title: 'Consumer Research',
    description:
      'Analyze audience preferences to improve marketing strategies, guide business planning, and better understand and connect with consumers.',
    image: imageFooterFeatureConsumerResearch,
    link: '',
  },
  {
    title: 'Brand Health',
    description:
      'Monitor key conversations around your brand, products, leadership and more with sentiment and trends analysis.',
    image: imageFooterFeatureBrandHealth,
    link: '',
  },
  {
    title: 'Competitor Comparison',
    description:
      'Track important discussions about your brand, products, leadership, and more through sentiment and trend analysis.',
    image: imageFooterFeatureCompetitorComparison,
    link: '',
  },
  {
    title: 'Trend Identification',
    description:
      'Monitor consumer behavior regarding specific topics to stay informed about the latest trends in your industry.',
    image: imageFooterFeatureTrendIdentification,
    link: '',
  },
  {
    title: 'Sentiment Research',
    description:
      'Optimize positioning by exploring customer feelings and opinions on specific topics, products, competitors, and more.',
    image: imageFooterFeatureSentimentResearch,
    link: '',
  },
  {
    title: 'Campaign Analysis',
    description:
      'Improve your positioning by analyzing customer sentiments and opinions on specific topics, products, competitors, and more.',
    image: imageFooterFeatureCampaignAnalysis,
    link: '',
  },
  {
    title: 'Industry Analysis',
    description: 'Spot trends and key business insights to boost market share and stay ahead of competitors.',
    image: imageFooterFeatureIndustryAnalysis,
    link: '',
  },
  {
    title: 'Influencer Recognition',
    description:
      'Recognize influencers and industry leaders by their following or post impact to foster brand advocates.',
    image: imageFooterFeatureInfluencerRecognition,
    link: '',
  },
  {
    title: 'Consumer Feedback',
    description:
      'Monitor discussions about your brand to uncover consumer attitudes and gain insights into the customer experience.',
    image: imageFooterFeatureConsumerFeedback,
    link: '',
  },
]

const Listening = () => {
  return (
    <App fullHeader>
      <SEO
        title="Social Media Listening | Vista Social"
        description="Vista Social Listening tools help brands of all sizes monitor social conversation to extract actionable insights, identify industry gaps and improve brand health. Request a demo."
        path="/social-media-listening/"
      />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" alignItems="center">
            <Grid
              mt="xl"
              mb={{ mobile: 0, desktop: 'xl' }}
              gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
              gridGap="l"
              width="100%"
              alignItems="center"
            >
              <Flex width="100%" justifyContent="center" alignItems="center">
                <Flex flexDirection="column">
                  <Flex width="100%" justifyContent="center" flexDirection="column">
                    <PoweredBy />
                    <Flex
                      mt="m"
                      flexDirection={{ mobile: 'row', desktop: 'column' }}
                      flexWrap="wrap"
                      justifyContent="center"
                    >
                      <H1
                        fontSize="xxxl"
                        fontWeight="bold"
                        color={COLOR_CONSTANTS.DENIM}
                        textAlign={{ mobile: 'center', desktop: 'left' }}
                      >
                        Uncover business-critical insights with{' '}
                        <H1
                          fontSize="xxxl"
                          fontWeight="bold"
                          color="primary"
                          textAlign={{ mobile: 'center', desktop: 'left' }}
                          as="span"
                        >
                          social listening
                        </H1>{' '}
                        that you can actually{' '}
                        <H1
                          fontSize="xxxl"
                          fontWeight="bold"
                          color="primary"
                          textAlign={{ mobile: 'center', desktop: 'left' }}
                          as="span"
                        >
                          afford
                        </H1>
                        .
                      </H1>
                    </Flex>
                    <H2
                      mt="m"
                      pt="s"
                      color="secondaryText"
                      fontSize="m"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      fontWeight="normal"
                      lineHeight={lineHeights.xxl}
                    >
                      Vista Social listening tools help brands of all sizes monitor social conversation to extract
                      actionable insights, identify industry gaps and improve brand health.
                    </H2>
                    <TrialTerms />
                    <Flex
                      mt="l"
                      alignItems="center"
                      justifyContent={{ mobile: 'center', desktop: 'flex-start' }}
                      flexDirection={{ mobile: 'column', mobileLarge: 'row' }}
                    >
                      <SelectPlanButton />
                    </Flex>
                    <Awards />
                  </Flex>
                </Flex>
              </Flex>
              <Flex width="100%" ml="auto" mr={{ mobile: 'auto', desktop: 0 }}>
                <HeaderImageAnimation />
              </Flex>
            </Grid>

            <Flex
              mt={{ mobile: 'l', tablet: 'xl' }}
              pt="m"
              flexDirection="column"
              alignItems="center"
              mb={{ mobile: 'l', desktop: 'l' }}
            >
              <G2FeaturesComponent />
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex mx="auto" maxWidth="780px">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              Use{' '}
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                Vista Social
              </H1>
              ’s Listening to achieve even more social media goals
            </H3>
          </Flex>

          <Flex mt="xl" justifyContent={{ mobile: 'center', desktop: 'space-between' }}>
            <Grid
              mx="auto"
              gridGap="l"
              gridTemplateColumns={{ mobileLarge: 'repeat(1, 1fr)', desktop: 'repeat(3, 1fr)' }}
            >
              {FEATURE_ITEMS.map(({ title, description, image }) => (
                <Flex
                  flexDirection="column"
                  key={title}
                  maxWidth={{ mobileLarge: '100%', desktop: '350px' }}
                  width="100%"
                  p="m"
                  bg={COLOR_CONSTANTS.WHITE}
                  borderRadius={radius.xl}
                >
                  <Flex alignItems="center">
                    <LocalImage src={image} alt={title} width="44px" height="44px" />
                    <Text ml="s" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="m" textAlign="left">
                      {title}
                    </Text>
                  </Flex>
                  <Text mt="m" fontSize="m" color="secondaryText" textAlign="left">
                    {description}
                  </Text>
                </Flex>
              ))}
            </Grid>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex mx="auto" flexDirection="column">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              Social listening that{' '}
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                every business can afford
              </H1>
            </H3>
            <Text mt="s" fontSize="s" color="secondaryText">
              Vista Social offers simple and transparent pricing that works for businesses of all sizes.
            </Text>
          </Flex>

          <Flex mt="xl" justifyContent={{ mobile: 'center', desktop: 'space-between' }}>
            <PricingComponent />
          </Flex>

          <Flex flexDirection="column" mt="xl">
            <Grid gridGap="l" gridTemplateColumns="1fr" maxWidth="700px" mx="auto">
              {LISTENING_FEATURE_ITEMS.map(({ name, description }) => {
                return (
                  <Flex flexDirection="column">
                    <Flex alignItems="center" justifyContent={{ mobile: 'center', tablet: 'flex-start' }}>
                      <Box borderRadius={radius.pill} width="8px" height="8px" minWidth="8px" bg={colors.primary} />
                      <Text ml="s" fontSize="xl" color={COLOR_CONSTANTS.DENIM} textAlign="left" fontWeight="bold">
                        {name}
                      </Text>
                    </Flex>
                    <Text mt="s" fontSize="m" color="secondaryText" textAlign={{ mobile: 'center', tablet: 'left' }}>
                      {description}
                    </Text>
                  </Flex>
                )
              })}

              <StyledNetworksWrapper justifyContent={{ mobile: 'center', tablet: 'flex-start' }}>
                {NETWORKS.map(({ image }) => (
                  <LocalImage
                    src={image}
                    alt="Your posts for the best performance on each social network."
                    width="34px"
                    height="34px"
                  />
                ))}
              </StyledNetworksWrapper>
            </Grid>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          pb={{ mobile: 'l', tablet: 'xxl' }}
          pt={{ mobile: 'l', tablet: 0 }}
          position="relative"
          maxWidth="1110px"
          width="100%"
          height="100%"
        >
          <Grid gridGap="xl" gridTemplateColumns="1fr">
            <Grid gridTemplateColumns={{ mobile: '1fr', tablet: 'repeat(2, 1fr)' }} gridGap="l" alignItems="center">
              <Flex order={{ mobile: 2, tablet: 2 }} maxWidth="540px" width="100%" mx="auto">
                <LocalImage
                  src={imageFeatureListeningQueries}
                  width="100%"
                  alt="Build sophisticated listening queries easily"
                />
              </Flex>

              <Flex
                order={{ mobile: 1, tablet: 1 }}
                flexDirection="column"
                alignItems={{ mobile: 'center', tablet: 'flex-start' }}
              >
                <Flex>
                  <Flex mt="xs" pt="xxs">
                    <Box borderRadius={radius.pill} width="16px" height="16px" minWidth="16px" bg={colors.primary} />
                  </Flex>

                  <Flex flexDirection="column" ml="s">
                    <Flex maxWidth={{ mobile: '100%', tablet: '440px' }}>
                      <Text color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="xl" textAlign="left">
                        Create sophisticated{' '}
                        <Text as="span" color="primary" fontWeight="bold" fontSize="xl">
                          listening queries{' '}
                        </Text>
                        easily
                      </Text>
                    </Flex>

                    <Flex mt="s" maxWidth={{ mobile: '524px', tablet: '440px' }}>
                      <Text color="secondaryText" textAlign="left" fontSize="m">
                        Quickly create queries to monitor millions of conversations on key topics, with no prior boolean
                        expertise or experience required.
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Grid>

            <Grid gridTemplateColumns={{ mobile: '1fr', tablet: 'repeat(2, 1fr)' }} gridGap="l" alignItems="center">
              <Flex order={{ mobile: 2, tablet: 1 }} maxWidth="540px" width="100%" mx="auto">
                <LocalImage src={imageFeatureFullScope} width="100%" alt="Access a full scope of data" />
              </Flex>

              <Flex
                order={{ mobile: 1, tablet: 2 }}
                flexDirection="column"
                alignItems={{ mobile: 'center', tablet: 'flex-start' }}
              >
                <Flex>
                  <Flex mt="xs" pt="xxs">
                    <Box borderRadius={radius.pill} width="16px" height="16px" minWidth="16px" bg={colors.primary} />
                  </Flex>

                  <Flex flexDirection="column" ml="s">
                    <Flex maxWidth={{ mobile: '524px', tablet: '440px' }}>
                      <Text color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="xl" textAlign="left">
                        Access a{' '}
                        <Text as="span" color="primary" fontWeight="bold" fontSize="xl">
                          complete scope
                        </Text>{' '}
                        of data
                      </Text>
                    </Flex>

                    <Flex mt="s" maxWidth={{ mobile: '100%', tablet: '440px' }}>
                      <Text color="secondaryText" textAlign="left" fontSize="m">
                        Leverage AI and automation to explore insights and modify filters in real-time—without altering
                        your query.
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Grid>

            <Grid gridTemplateColumns={{ mobile: '1fr', tablet: 'repeat(2, 1fr)' }} gridGap="l" alignItems="center">
              <Flex order={{ mobile: 2, tablet: 2 }} maxWidth="540px" width="100%" mx="auto">
                <LocalImage src={imageFeatureNoise} width="100%" alt="Cut through the noise" />
              </Flex>

              <Flex
                order={{ mobile: 1, tablet: 1 }}
                flexDirection="column"
                alignItems={{ mobile: 'center', tablet: 'flex-start' }}
              >
                <Flex>
                  <Flex mt="xs" pt="xxs">
                    <Box borderRadius={radius.pill} width="16px" height="16px" minWidth="16px" bg={colors.primary} />
                  </Flex>

                  <Flex flexDirection="column" ml="s">
                    <Flex maxWidth={{ mobile: '100%', tablet: '440px' }}>
                      <Text color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="xl" textAlign="left">
                        Cut through the{' '}
                        <Text as="span" color="primary" fontWeight="bold" fontSize="xl">
                          noise{' '}
                        </Text>
                      </Text>
                    </Flex>

                    <Flex mt="s" maxWidth={{ mobile: '524px', tablet: '440px' }}>
                      <Text color="secondaryText" textAlign="left" fontSize="m">
                        Effortlessly analyze billions of data points to pinpoint trends, insights, and key takeaways
                        that will shape your future strategy—in just seconds.
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Grid>
          </Grid>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex mx="auto" justifyContent="center">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              See how your team can{' '}
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                discover trends
              </H1>
            </H3>
          </Flex>

          <Flex mt="l" justifyContent="center">
            <Box height={{ mobile: pxToRem(56), tablet: pxToRem(44) }}>
              <StyledButtonScheduleDemo as="a" href="/demo">
                Schedule a demo
              </StyledButtonScheduleDemo>
            </Box>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex mx="auto" justifyContent="center">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              Monitor{' '}
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                millions of conversations
              </H1>{' '}
              with these features
            </H3>
          </Flex>

          <Flex mt="xl" justifyContent={{ mobile: 'center', desktop: 'space-between' }}>
            <StyledFooterFeaturesWrapper>
              {FOOTER_FEATURE_ITEMS.map(({ title, description, image }) => (
                <Flex
                  flexDirection="column"
                  key={title}
                  maxWidth={{ mobile: '100%', tablet: `calc(50% - ${space.s})`, desktop: `calc(25% - ${space.m})` }}
                  width="100%"
                  p="s"
                  bg={COLOR_CONSTANTS.WHITE}
                  borderRadius={radius.xl}
                >
                  <Flex alignItems="center" maxHeight="44px">
                    <LocalImage src={image} alt={title} width="44px" height="44px" />
                    <Text ml="s" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="m" textAlign="left">
                      {title}
                    </Text>
                  </Flex>
                  <Text mt="m" fontSize="m" color="secondaryText" textAlign="left">
                    {description}
                  </Text>
                </Flex>
              ))}
            </StyledFooterFeaturesWrapper>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          pt={{ mobile: 'l', tablet: 'xxl' }}
          pb="0"
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
            Achieve market-leading results with{' '}
            <H3 as="span" fontSize="3xl" fontWeight="bold" color="primary">
              Vista Social
            </H3>
          </H3>

          <Flex px="m" alignItems="center" flexDirection="column" textAlign="center">
            <Box width="100%" minHeight="920px">
              <Calendly link="https://calendly.com/d/4d7-x9f-r7z" />
            </Box>
          </Flex>
        </Container>
      </Wrapper>

      <GetStartedComponent activeKey={LISTENING} />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
            Frequently Asked{' '}
            <H3 as="span" fontSize="3xl" fontWeight="bold" color="primary">
              Questions
            </H3>
          </H3>
          <Flex flexDirection="column" mt="xl" width="100%">
            <GridDropdown faqs={FREQUENTLY_ASKED_QUESTIONS.all.slice(0, 4)} />
            <Flex mt="l" justifyContent="flex-end">
              <StyledLinkText as="a" href="/faq" textAlign="left">
                <Text fontSize="l" fontWeight="medium" textAlign="left" color="primary" as="span">
                  ALL OUR FAQS
                </Text>
                <LocalImage src={imageDoubleArrowRightBlue} mt="-2px" />
              </StyledLinkText>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Footer />
    </App>
  )
}

export default Listening
